<template>
  <v-app-bar app class="boxShawdom" style="background: white; height: 100px">
    <v-overlay :value="getOver"></v-overlay>
      <v-spacer></v-spacer>
      <v-avatar>
        <img src="../assets/user.png" alt="Photo user" />
      </v-avatar>
      <div>
        <p class="mb-1 mt-5 mx-5" style="font-size: 14px; font-weight: bold">
          {{ getUser.Perfile.nombre ? getUser.Perfile.nombre : ''}}
        </p>

      </div>
      <v-btn icon>
        <v-icon @click="logout">mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
</template>

<script>
import {mapActions, mapGetters,mapState} from "vuex";
export default {
  name: "Navbar",
  computed:{
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['getOver'])
  },
  methods: {
    ...mapActions(['setDraw',]),
    ...mapActions('auth',['logout',]),
  },
  created() {
  }
};
</script>

<style>
</style>
